import { useCallback, useMemo, useEffect, useState } from 'react';

import { useAccount, useReadContract, useWriteContract, useWaitForTransactionReceipt } from 'wagmi';
import { Setting2 } from 'iconic-react';

import { useTokenContext } from 'context/token.context';

import ConnectWallet from 'components/ConnectWallet';
import TokenInput, { FALLBACK_ADDRESS } from 'components/TokenInput';
import PriceDisplay from 'components/PriceDisplay';

import { nopeAbi, noBullAbi } from 'constants/abi';
import { TokenTypes } from 'constants/constants';

import NOPEImg from 'assets/images/nope.webp';
import NoBULLImg from 'assets/images/nobull.webp';
import Pls from 'assets/images/pls.webp';
import { showNotification } from 'libs/libs';

const Minter = () => {
  const { address } = useAccount();
  const { plsAmountInteger, nopeAmount, nopeBalance, nopePLSPrice } = useTokenContext();
  const { data: hash, writeContract, status, error } = useWriteContract();

  const [isMintable, setIsMintable] = useState(false);

  const { data: nopeAllowance, refetch: refetchNope } = useReadContract({
    address: process.env.REACT_APP_NOPE_CONTRACT_ADDRESS as `0x${string}`,
    abi: nopeAbi || [],
    functionName: 'allowance',
    args: [
      address || FALLBACK_ADDRESS,
      process.env.REACT_APP_NOBULL_CONTRACT_ADDRESS as `0x${string}`
    ]
  });

  useEffect(() => {
    const interval = setInterval(() => {
      refetchNope();
    }, 5000);

    return () => clearInterval(interval);
  }, [refetchNope]);

  const { isLoading } = useWaitForTransactionReceipt({ hash });

  useEffect(() => {
    if (error) {
      showNotification((error as any)?.shortMessage || 'Something went wrong', 'error');
    }
  }, [error]);

  useEffect(() => {
    if (isLoading === false && status === 'success') {
      showNotification('Transaction successful', 'success');
    }
  }, [isLoading, status]);

  const needAllowance = useMemo(() => {
    const nopeAllowanceBigInt: BigInt = (nopeAllowance as BigInt) || 0n;
    return parseInt(nopeAmount?.toString()) > parseInt(nopeAllowanceBigInt?.toString());
  }, [nopeAllowance, nopeAmount]);

  useEffect(() => {
    setIsMintable(!needAllowance && nopeAmount !== 0n);
  }, [nopeAmount, needAllowance]);

  const handleApprove = useCallback(async () => {
    if (nopeAmount > nopeBalance) {
      showNotification('Insufficient NOPE balance', 'error');
      return;
    }

    writeContract({
      address: process.env.REACT_APP_NOPE_CONTRACT_ADDRESS as `0x${string}`,
      abi: nopeAbi || [],
      functionName: 'approve',
      args: [
        process.env.REACT_APP_NOBULL_CONTRACT_ADDRESS as `0x${string}`,
        BigInt(nopeAmount.toString())
      ]
    });
  }, [nopeAmount, nopeBalance, writeContract]);

  const handleMint = useCallback(async () => {
    writeContract({
      address: process.env.REACT_APP_NOBULL_CONTRACT_ADDRESS as `0x${string}`,
      abi: noBullAbi || [],
      functionName: 'mint',
      args: [nopeAmount],
      value: BigInt(parseInt(nopeAmount.toString()) * parseFloat(nopePLSPrice) * 1.01)
    });
  }, [nopeAmount, nopePLSPrice, writeContract]);

  const handleButtonClick = useCallback(() => {
    if (status === 'pending' || isLoading) return;
    if (needAllowance) {
      handleApprove();
      return;
    }
    if (isMintable) {
      handleMint();
      return;
    }
  }, [handleApprove, handleMint, isLoading, isMintable, needAllowance, status]);

  return (
    <div className="flex w-full max-w-7xl items-start justify-center">
      <div className="flex w-full flex-col gap-y-4 rounded-xl border-2 border-theme bg-gray-700/20 px-4 py-6 shadow-2xl md:w-130">
        <TokenInput
          tokenType={TokenTypes.NOPE}
          classNames="rounded-md border-2 border-theme shadow-lg"
          tokenImage={NOPEImg}
          abi={nopeAbi}
          api={`https://api.dexscreener.com/latest/dex/pairs/pulsechain/${process.env.REACT_APP_NOPE_PAIR_ADDRESS}`}
          label="Send NOPE"
          contractAddress={process.env.REACT_APP_NOPE_CONTRACT_ADDRESS as `0x${string}`}
          tokenImageUrl={process.env.REACT_APP_SITE_URL + '/nope.webp'}
        />
        <TokenInput
          tokenType={TokenTypes.NOBULL}
          classNames="rounded-md border-2 border-theme shadow-lg"
          tokenImage={NoBULLImg}
          abi={noBullAbi}
          api={`https://api.dexscreener.com/latest/dex/pairs/pulsechain/${process.env.REACT_APP_NOBULL_PAIR_ADDRESS}`}
          label="Mint NoBULL"
          contractAddress={process.env.REACT_APP_NOBULL_CONTRACT_ADDRESS as `0x${string}`}
          tokenImageUrl={process.env.REACT_APP_SITE_URL + '/nobull.webp'}
        />
        <div className="flex w-full flex-col gap-y-2 rounded-md border-2 border-theme bg-gray-900/40 p-2 backdrop-blur-lg">
          <div className="flex justify-between border-b border-gray-600 py-2 font-bold text-theme">
            <span>Protocol Fee:</span>
            <div className="item-center flex gap-x-2">
              <img src={Pls} alt="PLS token" className="h-6 w-6" />
              <span className="pt-0.25">
                {Number(plsAmountInteger.toFixed(1)).toLocaleString('en')} PLS
              </span>
            </div>
          </div>
          <div className="flex flex-col gap-y-1">
            <PriceDisplay
              title="PLS"
              api={`https://api.dexscreener.com/latest/dex/pairs/pulsechain/${process.env.REACT_APP_WPLS_PAIR_ADDRESS}`}
            />
          </div>
        </div>
        {address ? (
          <button
            type="button"
            className="flex w-full items-center justify-center gap-x-2 rounded bg-theme px-4 py-1 font-semibold"
            onClick={handleButtonClick}
          >
            {status === 'pending' || isLoading ? (
              <Setting2 size={24} variant="Outline" className="animate-spin" />
            ) : needAllowance ? (
              'Approve NOPE'
            ) : isMintable ? (
              'Mint NoBULL'
            ) : (
              'Enter Amount'
            )}
          </button>
        ) : (
          <ConnectWallet />
        )}
      </div>
    </div>
  );
};

export default Minter;
