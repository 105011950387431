export enum TokenTypes {
  NOPE = 'NOPE',
  NOBULL = 'NOBULL',
  PLS = 'PLS'
}

export type PairsResponse = {
  schemaVersion: string;
  pairs: any[] | null;
};
