import { createContext, ReactNode, useContext, useState, useMemo } from 'react';

interface ITokenContext {
  nopeAmount: BigInt;
  setNopeAmount: (amount: BigInt) => void;
  nopeBalance: BigInt;
  setNopeBalance: (balance: BigInt) => void;
  noBullAmount: BigInt;
  setNoBullAmount: (amount: BigInt) => void;
  plsPrice: string;
  setPlsPrice: (price: string) => void;
  nopePrice: string;
  setNopePrice: (price: string) => void;
  noBullPrice: string;
  setNoBullPrice: (price: string) => void;
  nopePLSPrice: string;
  setNopePLSPrice: (price: string) => void;
  nopePriceInUSD: number;
  noBullPriceInUSD: number;
  plsPriceInUSD: number;
  nopeAmountInteger: number;
  plsAmountInteger: number;
}

export const TokenContext = createContext<ITokenContext>({} as ITokenContext);

export const DEFAULT_DECIMALS = 18;

interface ITokenContextProvider {
  children: ReactNode;
}

export const TokenContextProvider = (props: ITokenContextProvider) => {
  const { children } = props;

  const [nopeAmount, setNopeAmount] = useState<BigInt>(0n);
  const [nopeBalance, setNopeBalance] = useState<BigInt>(0n);
  const [noBullAmount, setNoBullAmount] = useState<BigInt>(0n);
  const [plsPrice, setPlsPrice] = useState<string>('0');
  const [nopePrice, setNopePrice] = useState<string>('0');
  const [noBullPrice, setNoBullPrice] = useState<string>('0');
  const [nopePLSPrice, setNopePLSPrice] = useState<string>('0');

  const nopeAmountInteger = useMemo(
    () => parseFloat(nopeAmount.toString()) / 10 ** DEFAULT_DECIMALS,
    [nopeAmount]
  );
  const plsAmountInteger = useMemo(
    () => (nopeAmountInteger * parseFloat(nopePLSPrice) * 101) / 100,
    [nopeAmountInteger, nopePLSPrice]
  );

  const nopePriceInUSD = useMemo(
    () => parseFloat(nopePrice) * (parseFloat(nopeAmount.toString()) / 10 ** DEFAULT_DECIMALS),
    [nopePrice, nopeAmount]
  );
  const noBullPriceInUSD = useMemo(
    () => parseFloat(noBullPrice) * (parseFloat(nopeAmount.toString()) / 10 ** DEFAULT_DECIMALS),
    [noBullPrice, nopeAmount]
  );
  const plsPriceInUSD = useMemo(
    () => parseFloat(plsPrice) * plsAmountInteger,
    [plsPrice, plsAmountInteger]
  );

  const value: ITokenContext = useMemo(
    () => ({
      nopeAmount,
      setNopeAmount,
      nopeBalance,
      setNopeBalance,
      noBullAmount,
      setNoBullAmount,
      plsPrice,
      setPlsPrice,
      nopePrice,
      setNopePrice,
      noBullPrice,
      setNoBullPrice,
      nopePLSPrice,
      setNopePLSPrice,
      nopePriceInUSD,
      noBullPriceInUSD,
      plsPriceInUSD,
      nopeAmountInteger,
      plsAmountInteger
    }),
    [
      noBullAmount,
      noBullPrice,
      noBullPriceInUSD,
      nopeAmount,
      nopeAmountInteger,
      nopeBalance,
      nopePLSPrice,
      nopePrice,
      nopePriceInUSD,
      plsAmountInteger,
      plsPrice,
      plsPriceInUSD
    ]
  );

  return <TokenContext.Provider value={value}>{children}</TokenContext.Provider>;
};

export const useTokenContext = () => useContext(TokenContext);
